import request from '@/utils/request'


// 查询用户提现列表
export function listWithdraw(query) {
  return request({
    url: '/user/user-withdrawals/list',
    method: 'get',
    params: query
  })
}

// 查询用户提现分页
export function pageWithdraw(query) {
  return request({
    url: '/user/user-withdrawals/page',
    method: 'get',
    params: query
  })
}

// 查询用户提现详细
export function getWithdraw(data) {
  return request({
    url: '/user/user-withdrawals/detail',
    method: 'get',
    params: data
  })
}

// 新增用户提现
export function addWithdraw(data) {
  return request({
    url: '/user/user-withdrawals/add',
    method: 'post',
    data: data
  })
}

// 修改用户提现
export function updateWithdraw(data) {
  return request({
    url: '/user/user-withdrawals/edit',
    method: 'post',
    data: data
  })
}

export function cancelWithDraw(data) {
  return request({
    url: '/user/user-withdrawals/cancelWithDraw',
    method: 'post',
    data: data
  })
}
// 删除用户提现
export function delWithdraw(data) {
  return request({
    url: '/user/user-withdrawals/delete',
    method: 'post',
    data: data
  })
}

export function approveWithDraw(data) {
  return request({
    url: '/user/user-withdrawals/approve',
    method: 'post',
    data: data
  })
}
export function getVillageAccount(data) {
  return request({
    url: '/user/user-withdrawals/getVillageAccount',
    method: 'get',
    data: data
  })
}
export function pageAccountBill(query) {
  return request({
    url: '/user/user-withdrawals/pageAccountBill',
    method: 'get',
    params: query
  })
}

