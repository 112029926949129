<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="提现金额" prop="money" >
        <a-input-number style='width: 80%' :precision='0' :min='1' step='1' :max='99999' v-model="form.money" placeholder="请输入提现金额(整数，最少1元)" />
<!--        <a-input v-model="form.money" placeholder="请输入提现金额" />-->
      </a-form-model-item>
<!--      <a-form-model-item label="实际需要转账金额" prop="realMoney" >-->
<!--        <a-input v-model="form.realMoney" placeholder="请输入实际需要转账金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="0-未审核 1-审核未通过 2-审核已通过" prop="status" >-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="提现类型" prop="withdrawType" >
        <a-select v-model="form.withdrawType" placeholder="请选择">
          <a-select-option value="1">支付宝</a-select-option>
          <a-select-option value="2">微信</a-select-option>
          <a-select-option value="3">银行卡</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="提现账号姓名" prop="accountName" >
        <a-input v-model="form.accountName" placeholder="请输入提现账号姓名" />
      </a-form-model-item>
      <a-form-model-item label="提现账号" prop="accountNum" >
        <a-input v-model="form.accountNum" placeholder="请输入提现账号" />
      </a-form-model-item>
      <a-form-model-item v-if='form.withdrawType == 1 || form.withdrawType == 2'  label="收款二维码" prop="qrCode" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.qrCode"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload(info)'
          :customRequest='(info) => fileUploadRequest(info)'
        >
          <img v-if="form.qrCode" :src="form.qrCode" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item v-if="form.withdrawType == 3" label="银行名称" prop="bankName" >
        <a-input v-model="form.bankName" placeholder="请输入银行名称" />
      </a-form-model-item>
      <a-form-model-item v-if="form.withdrawType == 3" label="银行卡开户行地址" prop="bankAdress" >
        <a-input v-model="form.bankAdress" placeholder="请输入银行卡开户行地址" />
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <span style='color: #00a4f8'>
         <p>可提现余额：{{account.money}} 元</p>
        <p>最低提现金额：{{account.min}} 元</p>
        <p>提现手续费：{{account.poundage}} %</p>
        <p>提现规则：{{account.rule}}</p>
      </span>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWithdraw, addWithdraw, updateWithdraw } from '@/api/user/withdraw'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      uploadLoading:false,
      account:[],
      // 表单参数
      form: {
        id: null,

        userId: null,

        money: null,

        realMoney: null,

        status: '0',
        qrCode: null,
        accountNum: null,

        accountName: null,

        bankName: null,

        bankAdress: null,

        serviceChargeNum: null,

        withdrawType: null,

        orderNo: null,

        auditOpinion: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        money: [
          { required: true, message: '提现金额不能为空', trigger: 'blur' }
        ],
        withdrawType: [
          { required: true, message: '提现类型不能为空', trigger: 'blur' }
        ],
        accountName: [
          { required: true, message: '提现账号名称不能为空', trigger: 'blur' }
        ],
        accountNum: [
          { required: true, message: '提现账号不能为空', trigger: 'blur' }
        ],
        qrCode: [
          { required: true, message: '收款二维码不能为空', trigger: 'blur' }
        ],
        bankName: [
          { required: true, message: '银行名称不能为空', trigger: 'blur' }
        ],

      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        money: null,
        realMoney: null,
        qrCode: null,
        status: '0',
        accountNum: null,
        accountName: null,
        bankName: null,
        bankAdress: null,
        serviceChargeNum: null,
        withdrawType: null,
        orderNo: null,
        auditOpinion: null,
        createTime: null,
        remark: null,
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.account = row
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWithdraw({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(fileInfo) {

      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {

          this.loading = false;
          this.form.qrCode = res[0].url;
        },
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWithdraw(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addWithdraw(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
